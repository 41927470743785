import React, { useEffect, useRef, useState } from "react";
import { json, LoaderFunctionArgs } from "@remix-run/node";
import { getAccessToken } from "~/utils/ensure-authenticated.server";
import client, { getServerClient } from "~/graphql";
import {
  EventFilter,
  MindspaceEventsDocument,
  MindspaceEventsQuery,
  MindspaceEventsQueryVariables,
} from "~/graphql/__generated";
import {
  MetaFunction,
  useLoaderData,
  useNavigate,
  useSearchParams,
} from "@remix-run/react";
import MindspaceEventCard from "~/components/events/mindspace-event-card";
import usePageView from "~/hooks/page-view";
import PageContainer from "~/components/containers/page-container";
import { Tabs, TabsList, TabsTrigger } from "@mindstonehq/ui";
import { useCurrentSpace } from "~/hooks/context-hook";
import { currentSpaceUniqueName } from "~/hooks/check-authenticated";
import useInfiniteScroll from "~/hooks/infinite-scroll";
import metrics from "~/utils/metrics";

export const meta: MetaFunction<typeof loader> = ({ data }) => {
  if (!data) {
    return [{ title: " Mindstone" }];
  }
  return [
    {
      title: `Mindstone - Events`,
    },
    {
      name: "description",
      content: `All events organised by Mindstone`,
    },
  ];
};

export async function loader({ request, params }: LoaderFunctionArgs) {
  const accessToken = await getAccessToken(request);
  const uniqueName = currentSpaceUniqueName(request);

  const url = new URL(request.url);
  let filter = url.searchParams.has("filter")
    ? url.searchParams.get("filter")?.toUpperCase()
    : "UPCOMING";

  if (filter !== EventFilter.Past && filter !== EventFilter.Upcoming) {
    filter = undefined;
  }

  const { data, error } = await getServerClient()
    .query<MindspaceEventsQuery, MindspaceEventsQueryVariables>({
      query: MindspaceEventsDocument,
      fetchPolicy: "no-cache",
      context: {
        headers: {
          Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
        },
      },
      variables: {
        uniqueName,
        eventsSize: 10,
        eventsOffset: 0,
        eventsFilter: filter,
      },
    })
    .catch((error) => {
      throw error;
    });
  if (error) {
    throw error;
  }

  const fullyPublic =
    data.mindspace.configuration?.public &&
    !data.mindspace.configuration.inviteOnly;
  if (!data.mindspace.modules.events || (!fullyPublic && !accessToken)) {
    throw new Response(null, {
      status: 403,
      statusText: "Not Found",
    });
  }
  return json(data);
}

export default function Events() {
  usePageView("Events");
  const form = useRef<HTMLFormElement>(null);
  const data = useLoaderData<typeof loader>();

  const [initialData, setInitialData] = useState({
    items: data?.mindspace.events?.list?.list || [],
    hasMore: !!data?.mindspace.events?.list?.hasMore,
    nextItem: data?.mindspace.events?.list?.nextItem,
  });
  const { items, loading, hasMore, sentinelRef } = useInfiniteScroll(
    initialData,
    (offset) =>
      fetchEvents(offset).then((res) => ({
        items: res?.data?.mindspace.events?.list?.list || [],
        hasMore: !!res?.data?.mindspace.events?.list?.hasMore,
        nextItem: res?.data?.mindspace.events?.list?.nextItem,
      })),
  );
  useEffect(() => {
    setInitialData({
      items: data?.mindspace.events?.list?.list || [],
      hasMore: !!data?.mindspace.events?.list?.hasMore,
      nextItem: data?.mindspace.events?.list?.nextItem,
    });
  }, [data]);
  const currentSpace = useCurrentSpace();

  const [searchParams, setSearchParams] = useSearchParams();
  const filterParam = searchParams.has("filter")
    ? searchParams.get("filter")?.toUpperCase()
    : EventFilter.Upcoming;
  const filterVal =
    filterParam === EventFilter.Past || filterParam === EventFilter.Upcoming
      ? filterParam
      : "";
  const navigate = useNavigate();
  const containerRef = useRef<HTMLDivElement>(null);

  const fetchEvents = (offset: number) => {
    return client.query<MindspaceEventsQuery, MindspaceEventsQueryVariables>({
      query: MindspaceEventsDocument,
      variables: {
        uniqueName: currentSpace.uniqueName,
        eventsSize: 10,
        eventsOffset: offset,
        eventsFilter: filterVal || null,
      },
      fetchPolicy: "no-cache",
    });
  };

  return (
    <PageContainer title="Events" className="content-start" noMargin>
      <div
        className={
          "col-span-12 sticky top-0 bg-background grid grid-cols-1 gap-2 md:grid-cols-2 " +
          "p-4 sm:p-5 md:p-6 lg:p-8 xl:p-10 2xl:p-12"
        }
      >
        {!!data.mindspace.events?.sponsors.length && (
          <div className="md:order-last flex flex-row items-center rounded-xl gap-2">
            <div className="typography-body-md-bold">Sponsors</div>
            {data.mindspace.events.sponsors.map((sponsor) => (
              <div
                className="light bg-muted rounded-xl h-14"
                key={sponsor.name}
              >
                <a
                  href={sponsor.url}
                  target="_blank"
                  onClick={() => {
                    metrics.track("tapped_global_sponsor", {
                      ...sponsor,
                    });
                  }}
                >
                  <div className="flex justify-center items-center h-full p-2">
                    <img
                      className="object-contain h-full"
                      src={sponsor.logourl}
                      alt={sponsor.name}
                    />
                  </div>
                </a>
              </div>
            ))}
          </div>
        )}
        <Tabs
          defaultValue={filterParam}
          onValueChange={(v) => {
            navigate(`?filter=${v}`);
          }}
          className="justify-self-end md:justify-self-start text-sm w-full md:w-auto md:col-start-1"
        >
          <TabsList className="w-full md:w-auto">
            <TabsTrigger value="all" className="w-full md:w-auto">
              All
            </TabsTrigger>
            <TabsTrigger value="UPCOMING" className="w-full md:w-auto">
              Upcoming
            </TabsTrigger>
            <TabsTrigger value="PAST" className="w-full md:w-auto">
              Past
            </TabsTrigger>
          </TabsList>
        </Tabs>
      </div>

      {items.length ? (
        <div className=" col-span-12 grid grid-cols-12 gap-4 sm:gap-5 lg:gap-6 2xl:gap-8 p-4 sm:p-5 md:p-6 lg:p-8 xl:p-10 2xl:p-12 !pt-0">
          {items.map((event) => (
            <MindspaceEventCard
              linkTo={`/events/${event.slug}`}
              key={event.id}
              event={event}
              className={
                "col-span-12 sm:col-span-6 lg:col-span-4 2xl:col-span-3"
              }
            />
          ))}
          {hasMore && <div ref={sentinelRef} className="h-1" />}
        </div>
      ) : (
        <div className="col-span-12 flex flex-col items-center justify-center flex-1  rounded-xl">
          <h5 className="p-10">No events</h5>
        </div>
      )}
    </PageContainer>
  );
}
