import {
  BanknotesIcon,
  ClockIcon,
  MapPinIcon,
} from "@heroicons/react/24/outline";

import { Link, useParams } from "@remix-run/react";
import moment from "moment";
import React from "react";
import { MindspaceEventCardFragment } from "~/graphql/__generated";
import { isFrontend } from "~/utils";
import { Card, CardContent, CardHeader, Skeleton } from "@mindstonehq/ui";
import MindspaceEventTimes from "./mindspace-event-times";

export default function MindspaceEventCard({
  linkTo,
  event,
  className,
}: {
  event: MindspaceEventCardFragment;
  linkTo?: string;
  className?: string;
}) {
  const { uniqueName } = useParams();
  const frontend = isFrontend();
  return (
    <Link
      to={linkTo || `/mindspace/${uniqueName}/events/event/${event.slug}`}
      className={className}
    >
      <Card>
        <CardHeader className="px-0 py-0 space-y-0 overflow-hidden">
          <img
            src={event.backgroundImage.medium}
            className="w-full  h-[200px] rounded-lg object-contain"
          />
        </CardHeader>
        <CardContent className="p-3">
          <div className="flex divide-x divide-theme-border-medium space-x-3">
            <div className="flex flex-col items-center justify-center pl-3">
              {frontend ? (
                <>
                  <h4>{moment(event.startDatetime).format("DD")}</h4>
                  <div className="t-body-xs-light text-theme-text-secondary">
                    {moment(event.startDatetime).format("MMM")}
                  </div>
                </>
              ) : (
                <>
                  <Skeleton className="w-8 h-6" />
                  <Skeleton className="w-8 h-4 mt-2" />
                </>
              )}
            </div>
            <div className="flex flex-col space-y-1 pl-3">
              <div className="t-body-sm-bold">{event.name}</div>
              <div className="flex flex-col space-y-0.5">
                <div className="flex items-center text-theme-text-secondary space-x-1">
                  <ClockIcon className="h-3 w-3" />
                  {frontend && (
                    <div className="t-body-xs-regular">
                      <MindspaceEventTimes event={event} />
                    </div>
                  )}
                </div>
                <div className="flex items-center text-theme-text-secondary space-x-1">
                  <MapPinIcon className="h-3 w-3" />
                  <div className="t-body-xs-regular">{event.locationShort}</div>
                </div>
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
    </Link>
  );
}
